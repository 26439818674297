<template>
  <div class="row mx-0 white_bg">
    <div class="col-12 py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center fs-1 mb-3">
            Thank you!
          </div>
          <div class="col-12 col-md-7 col-xl-6 text-center fs-3">
            We've received your details.<br/>Someone will be in contact with you shortly.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
      this.$router.push('/')
    }, 5000)
  }
}
</script>
<style scoped>

</style>
